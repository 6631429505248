.ph-keen-slider {
  .keen-slider__slide:focus-within {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(112, 32, 130, 0.25);
  }

  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }

  .dot:focus {
    outline: 0;
    box-shadow: none;
  }

  .dot.active {
    background: #000;
  }

  .arrow-x {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    color: white;
    font-size: 24px;
    background: black;
    height: 34px;
    width: 22px;
    opacity: 0.4;
    cursor: pointer;
  }

  .arrow-y {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    -webkit-transform: translateX(50%);
    color: white;
    font-size: 24px;
    background: black;
    line-height: 1;
    padding: 0 8px;
    opacity: 0.4;
    cursor: pointer;
  }

  .arrow--down {
    bottom: 0;
  }

  .arrow--up {
    bottom: auto;
    top: 0;
  }

  .arrow--left {
    left: 5px;
  }

  .arrow--right {
    left: auto;
    right: 5px;
  }

  .arrow--disabled {
    color: rgba(255, 255, 255, 0.7);
  }
}
