@import 'styles/_colors.scss';

$theme-colors: (
  'primary': $dark_background_color,
  'danger': $danger,
  'success': $success,
  'warning': $warning,
);

@import '~bootstrap/scss/bootstrap';
@import 'ngx-toastr/toastr-bs4-alert.scss';
@import '~keen-slider/keen-slider.scss';

.btn {
  font-weight: $font-weight-bold;
}

/* stylelint-disable */
.form-control {
  &::-webkit-input-placeholder {
    color: $light-grey;
  }
  &:-moz-placeholder {
    color: $light-grey;
  }
  &::-moz-placeholder {
    color: $light-grey;
  }
  &::placeholder {
    color: $light-grey;
  }
  &:-ms-input-placeholder {
    color: $light-grey;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '>';
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 0rem;
  list-style: none;
  background-color: inherit;
  border-radius: 0;
}
