@import '_ph-bootstrap-variables';

// Custom variables
$dark_background_color: #682b8c;
$light_background_color: #f9f5fb;

$light-grey: #d0d0d0;
$very-light-grey: #ededed;
$mostly-white-grey: #f9f9f9;
$very-dark-grey: #4b4b4b;
$lola: #f0eaf0;
$amethyst: #a05bca;
$eminence: #5d277e;
$white-pointer: #fcf6ff;
$border_color: #ede0f5;
$link_color: #5d277e;
$affair: #774198;
$eminence: #5d277e;

$selected_item_background_color: $dark_background_color;
$selected_item_tex_color: $white;
