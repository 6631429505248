@import 'styles/_colors.scss';
@import 'styles/_variables.scss';
@import 'styles/_validation.scss';
@import 'styles/text-utility';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.0/font/bootstrap-icons.css');
@import 'styles/keen-slider';
@import '~@ng-select/ng-select/themes/default.theme.css';

.truncate {
  width: 10em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.link-highlight {
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

html {
  // equals 1rem
  font-size: 16px;
}

body {
  overflow-y: overlay;
}

@media #{$screen-lt-md} {
  html {
    font-size: 16px;
  }

  .d-lt-none {
    display: none;
  }
}

.btn-uppercase-lg {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.8;
}

.line-through {
  text-decoration: line-through;
}

.font-size-bigger {
  font-size: 1.4em;
}

.font-size-smaller {
  font-size: 0.85em;
}

.font-size-tiny {
  font-size: 0.7em;
}

.pointer {
  cursor: pointer;
}

input[type='radio'] {
  accent-color: $primary;
}

.braintree-form .braintree-form__notice-of-collection {
  display: none;
}
